<template>
  <b-modal
    id="update-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
    @close="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ powerDataItem.id ? '編輯權限' : '新增權限' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 狀態 -->
        <b-form-group label-for="state">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                狀態
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="powerData.state"
            :options="stateOptions"
            :clearable="false"
            :reduce="option => option.value"
            placeholder="請選擇狀態"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>

            <template v-slot:option="option">
              <div class="d-flex align-items-center">
                <span
                  class="mr-50 border state-dot"
                  :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                />
                {{ option.label }}
              </div>
            </template>

            <template #selected-option="option">
              <div class="d-flex align-items-center">
                <span
                  class="mr-50 border state-dot"
                  :class="{ 'dot-gray': !option.value, 'dot-green-selected': option.value }"
                />
                {{ option.label }}
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 權限名稱 -->
        <validation-provider
          #default="{ errors }"
          name="權限名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                權限名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="powerData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="powerDataItem.name ? powerDataItem.name : '請輸入權限名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 權限群組 -->
        <validation-provider
          #default="{ errors }"
          name="群組"
          rules="required"
        >
          <b-form-group label-for="group">
            <template #label>
              <label class="mb-0">
                群組
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="powerData.group"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="powerDataItem.group ? powerDataItem.group : '請輸入群組名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 權限路由 -->
        <validation-provider
          #default="{ errors }"
          name="權限路由"
          rules="required"
        >
          <b-form-group label-for="route_path">
            <template #label>
              <label class="mb-0">
                權限路由
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="route_path"
              v-model="powerData.route_path"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="powerDataItem.route_path ? powerDataItem.route_path : '請輸入權限路由'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import { usePowerList, usePowerSetting } from '../usePower'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    // BFormSpinbutton,

    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    powerDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
    }
  },
  methods: {
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetPowerData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('update-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '權限名稱',
        group: '群組',
        route_path: '權限路由',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.powerData[Object.keys(requiredFields)[i]] === null || this.powerData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.powerDataItem.id) {
        this.setPowerUpdate({
          power_id: this.powerDataItem.id,
          data: {
            ...this.powerData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetPowerData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setPowerCreate({
          ...this.powerData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('refetch-data')
            this.resetPowerData()
            this.$nextTick(() => {
              this.$bvModal.hide('update-modal')
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        ...this.syncObject(this.blankPowerData, this.powerDataItem),
      }
      this.powerData = resolveData
      this.$bvModal.show('update-modal')
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      setPowerCreate,
      setPowerUpdate,
    } = usePowerList()

    const {
      stateOptions,
      getMetaListData,
      departmentsOptions,
      syncObject,
    } = usePowerSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankPowerData = {
      name: null,
      route_path: null,
      group: null,
      state: false,
    }

    const powerData = ref(JSON.parse(JSON.stringify(blankPowerData)))

    const resetPowerData = () => {
      powerData.value = JSON.parse(JSON.stringify(blankPowerData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPowerData)

    return {
      isBusy,

      powerData,
      stateOptions,
      departmentsOptions,
      blankPowerData,
      setPowerCreate,
      setPowerUpdate,
      getMetaListData,

      resetPowerData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}

.dot-green-selected {
  background:radial-gradient(circle closest-side at center,#66ff66,#1bb425);
}

.dot-yellow-selected {
  background:radial-gradient(circle closest-side at center,#f6e9ad,#f6bc01);
}

.dot-red-selected {
  background:radial-gradient(circle closest-side at center,#f6adad,#f32929);
}
</style>
