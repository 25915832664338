import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)權限列表
    getPowerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/power', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)權限
    setPowerCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/power', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)權限
    setPowerUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/power/${resolveData.power_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)權限
    setPowerDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/power/${resolveData.power_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
